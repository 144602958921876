import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LoyaltyActions = createActionGroup({
  source: 'Loyalty',
  events: {
    '[Loyalty] Get WellBet': emptyProps(),
    '[Loyalty] Set WellBet Success': props<{ data: any }>(),
    '[Loyalty] Set WellBet Failure': emptyProps(),

    '[Loyalty] Get Reefback': emptyProps(),
    '[Loyalty] Set Reefback Success': props<{ data: any }>(),
    '[Loyalty] Set Reefback Failure': emptyProps(),
    '[Loyalty] Get WellBetAndReefback': emptyProps(),

    '[Loyalty] Set TradableBalance Success': props<{ data: any }>(),
    '[Loyalty] Set TradableBalance Failure': emptyProps(),

    '[Loyalty] Set RewardedStatistics Success': props<{ data: any }>(),
    '[Loyalty] Set RewardedStatistics Failure': emptyProps(),

    '[Loyalty] Set RequesData Success': props<{ data: any }>(),

    '[Loyalty] Get CombinedLoyaltyData': emptyProps(),
    // '[Loyalty] Set UserTurnover Success': props<{ data: any }>(),
    // '[Loyalty] Set UserTurnover Failure': emptyProps(),

    '[Loyalty] Get CalendarPendinRewards': emptyProps(),
    '[Loyalty] Set CalendarPendinRewards Success': props<{ data: any }>(),
    '[Loyalty] Set CalendarPendinRewards Failure': emptyProps(),

    '[Loyalty] Get FinishedQuestsCount': emptyProps(),
    '[Loyalty] Set FinishedQuestsCount Success': props<{ data: any }>(),
    '[Loyalty] Set FinishedQuestsCount Failure': emptyProps(),

    '[Loyalty] Get UsetTurnover': emptyProps(),
    '[Loyalty] Set UserTurnover Success': props<{ data: any }>(),
    '[Loyalty] Set UserTurnover Failure': emptyProps(),

    '[Loyalty] Set Cashback Success': props<{ data: any }>(),
    '[Loyalty] Set Cashback Failure': emptyProps(),
  },
});
export const transferLoyaltydataActions = createActionGroup({
  source: 'TransferData',
  events: {
    '[TransferData] Set RequesData Success': props<{ data: any }>(),
  },
});
